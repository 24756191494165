var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CyNotification',{attrs:{"theme":"error","content":_vm.credentialsErrors}}),(_vm.showLegend)?_c('CyNotification',{attrs:{"data-cy":"what-is-cr","closeable":"","title":_vm.$t('whatIsCR')},on:{"close":_vm.$toggle.showLegend}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('legendTextBegin'))}}),_c('router-link',{staticClass:"cy-link",attrs:{"to":{ name: 'credentials' }}},[_vm._v(" "+_vm._s(_vm.$t('Credential'))+" ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('legendTextEnd'))}})],1):_vm._e(),_c('CyDataTableYdApi',{ref:"cyDataTable",attrs:{"id":"cy-catalog-repositories-table","fetch-available":{ keyPath: 'catalogRepositories' },"headers":_vm.$static.headers,"link-builder":_vm.getLinkTarget,"bulk":_vm.hasBulkModeEnabled,"searchable-fields":_vm.$static.searchableFields,"key-field":"canonical"},scopedSlots:_vm._u([{key:"table-cmp-header-actions",fn:function(ref){
var selected = ref.selected;
return [(!_vm.showLegend)?_c('a',{staticClass:"cy-link show-legend mr-4",attrs:{"data-cy":"what-is-cr-button"},on:{"click":_vm.$toggle.showLegend}},[_vm._v(" "+_vm._s(_vm.$t('whatIsCR'))+" ")]):_vm._e(),(!_vm._.isEmpty(selected))?[_c('CyDevBtn',{staticClass:"my-0 mr-2",attrs:{"wrap-dev-text":false},nativeOn:{"click":function($event){return _vm.setMissingOwners(selected)}}},[_vm._v(" Set missing owner ")]),_c('CyButton',{attrs:{"theme":"error","icon":"delete","data-cy":"delete-catalog-repo-btn"},on:{"click":function($event){return _vm.openDeleteModal(selected)}}},[_vm._v(" "+_vm._s(_vm.$tc('deleteCatalogReposBtn', selected.length, { nb: selected.length }))+" ")])]:_c('CyButton',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:('CreateServiceCatalogSource'),expression:"'CreateServiceCatalogSource'"}],attrs:{"data-cy":"create-catalog-repo-btn","icon":"add","to":{ name: 'newCatalogRepository' }},on:{"click":function($event){return _vm.$gtm.trackEvent(_vm.$static.gtmStacksEvents.stacksCatalogRepositoriesAddPrivateCatalog)}}},[_vm._v(" "+_vm._s(_vm.$t('addCatalogRepository'))+" ")]),(_vm.refreshedRepository)?_c('CyModal',{attrs:{"modal-type":"success","cancel-btn-text":_vm.$t('forms.btnClose'),"header-title":("" + (_vm.$t('repoRefreshSuccess', { repo: _vm.refreshedRepository }))),"cancel-btn-func":function () { return _vm.refreshedRepository = null; },"action-btn-hidden":""}},[(_vm.repositoryStackChanges.length)?_c('div',[_c('div',{staticClass:"d-flex justify-space-between"},_vm._l((_vm.repositoryStackChanges),function(ref){
var typeOfChange = ref[0];
var stacks = ref[1];
return _c('div',{key:typeOfChange},[_c('span',{staticClass:"font-weight-bold "},[_vm._v(" "+_vm._s((typeOfChange + " " + (_vm._.toLower(_vm.$t('untranslated.stacks')))))+" ")]),_c('CyTagList',{staticClass:"d-flex flex-column mt-2",attrs:{"tags":stacks},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
var tag = ref.tag;
return [_c('CyTag',{attrs:{"variant":_vm.stackVariant(typeOfChange)}},[_vm._v(" "+_vm._s(tag.canonical)+" ")])]}}],null,true)})],1)}),0)]):_c('p',[_vm._v(" "+_vm._s(_vm.$t('noStacksChanged'))+" ")])]):_vm._e(),(_vm.showDeleteModal)?_c('CyModal',{attrs:{"header-title":_vm.$tc('confirmDeleteTitle', _vm.toDelete.length),"action-btn-func":_vm.onDelete,"cancel-btn-func":_vm.closeDeleteModal,"action-btn-text":_vm.$tc('deleteCatalogReposBtn', _vm.toDelete.length, { nb: _vm.toDelete.length }),"loading":_vm.isDeleting,"small":"","modal-type":"delete"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('forms.cannotBeUndone'))+" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$tc('areYouSure', _vm.toDelete.length))}})]),(_vm.toDelete.length > 1)?_c('ul',{staticClass:"items-to-delete"},_vm._l((_vm.toDelete),function(ref){
var canonical = ref.canonical;
var name = ref.name;
var url = ref.url;
return _c('li',{key:canonical},[_c('div',[_c('h3',[_vm._v(_vm._s(name))]),_c('p',{staticClass:"url"},[_vm._v(" "+_vm._s(url)+" ")])])])}),0):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.$t('confirmDeleteRepository')))])]):_vm._e()]}},{key:"table-cmp-body-row",fn:function(ref){
var item = ref.props.item;
return [_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[(item.credential_canonical)?_c('CyButton',{key:item.canonical,staticClass:"credential-link",attrs:{"theme":"grey","variant":"tertiary","light":"","sm":"","disabled":!_vm.canGoToCredential(item),"readonly":!_vm.canGoToCredential(item),"to":{ name: 'credential', params: { credentialCanonical: item.credential_canonical, backRouteTo: 'catalogRepositories' } }},on:{"click":function($event){$event.stopPropagation();}}},[(_vm.getCredential(item.credential_canonical).type)?_c('CyIconCredential',{attrs:{"type":_vm.getCredential(item.credential_canonical).type}}):_vm._e(),_c('span',{staticClass:"credential-link__name"},[_vm._v(" "+_vm._s(_vm.getCredential(item.credential_canonical).name)+" ")])],1):_vm._e()],1),_c('td',[(_vm.canUpdateOwner(item))?_c('CyFormsAssignOwner',{attrs:{"errors":_vm.errors,"form-content":item,"action-btn-func":_vm.assignNewOwner}}):_c('CyButton',{attrs:{"to":{
            name: 'member',
            params: {
              id: item.owner.id,
              backRouteTo: 'catalogRepositories',
            },
          },"disabled":!_vm.canGoToMember(item),"readonly":!_vm.canGoToMember(item),"theme":"grey","variant":"tertiary","sm":"","member-link":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('CyMember',{attrs:{"member":item.owner,"simple":"","sm":""}})],1)],1),_c('td',[_vm._v(_vm._s(item.branch))]),_c('td',[_c('div',[_c('CyCopyBtn',{staticClass:"mr-2",attrs:{"copy-value":item.url,"small":""}}),_vm._v(" "+_vm._s(item.url)+" ")],1)]),_c('td',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:(['RefreshServiceCatalogSource', item.canonical]),expression:"['RefreshServiceCatalogSource', item.canonical]"}],staticClass:"refresh-column-cell",on:{"click":function($event){$event.stopPropagation();}}},[_c('CyTooltip',{attrs:{"left":"","disabled":_vm.refreshingCR[item.canonical]},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('CyButton',_vm._g({staticClass:"refresh-cr-btn",attrs:{"icon":"refresh","variant":"tertiary","icon-only":"","disabled":_vm.refreshingCR[item.canonical],"loading":_vm.refreshingCR[item.canonical]},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.refreshCR(item)}}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('refreshCR')))])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }